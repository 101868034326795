<template>
  <div>
    <client-header-slot />
    <!-- <header-slot :clients-search="true" :global-search="true">
      <template #actions>
        <b-row class="w-100">
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                placeholder="Search by Name, Account or the last 4 digits of Phone number"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot> -->
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'creditexperts-digital-clients-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Digital</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :role-id="currentUser.role_id"
      :program-id="programId"
      @closeModal="closeGlobalSearch"
    />
  </div>
</template>

<script>
import ClientHeaderSlot from "@/views/ce-digital/sub-modules/connection/views/clients/components/headerSlot.vue";
import { mapGetters } from "vuex";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalGlobalSearch,
    ClientHeaderSlot
  },
  data() {
    return {
      modalGlobalSearch: false,
      clientsGlobal: [],
      searchText: "",
      programId: 3,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
  },
};
</script>
